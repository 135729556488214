import React from "react"
const { Component } = React;
import ImageGallery from 'react-image-gallery';
import "../styles/image-gallery.scss";

export default class Gallery extends React.Component {

  _renderVideo(item) {
    return (
      <div>     
                <video width="100%" height="100%" controls>
                <source src="https://www.pansurg.org/remotegallery/holovideo1.mp4" type="video/mp4" />
                </video>
                
            </div>
    );
  }
  

  render = () => {

const images = [
    {
      original: 'https://www.pansurg.org/remotegallery/remote1.jpg',
      thumbnail: 'https://www.pansurg.org/remotegallery/remote1.jpg',
      description: 'PanSurg x HoloLens'
    },
    {
      original: 'https://www.pansurg.org/remotegallery/remote2.jpg',
      thumbnail: 'https://www.pansurg.org/remotegallery/remote2.jpg',
    },
    {
      original: 'https://www.pansurg.org/remotegallery/remote3.jpg',
      thumbnail: 'https://www.pansurg.org/remotegallery/remote3.jpg',
    
    },
    {
        original: 'https://www.pansurg.org/remotegallery/remote4.jpg',
        thumbnail: 'https://www.pansurg.org/remotegallery/remote4.jpg',
      },
      {
        original: 'https://www.pansurg.org/remotegallery/remote5.jpg',
        thumbnail: 'https://www.pansurg.org/remotegallery/remote5.jpg',
      },
      {
        original: 'https://www.pansurg.org/remotegallery/remote6.jpg',
        thumbnail: 'https://www.pansurg.org/remotegallery/remote6.jpg',
      },
      {
        original: 'https://www.pansurg.org/remotegallery/remote7.jpg',
        thumbnail: 'https://www.pansurg.org/remotegallery/remote7.jpg',
      },
      {
        original: 'https://www.pansurg.org/remotegallery/remote8.jpg',
        thumbnail: 'https://www.pansurg.org/remotegallery/remote8.jpg',
      },
      {
        original: 'https://www.pansurg.org/remotegallery/remote9.jpg',
        thumbnail: 'https://www.pansurg.org/remotegallery/remote9.jpg',
      },
      {
        original: 'https://www.pansurg.org/remotegallery/remote11.jpg',
        thumbnail: 'https://www.pansurg.org/remotegallery/remote11.jpg',
      },
      {
        thumbnail: 'https://www.pansurg.org/thumbnails/videothumb1.png',
        embedUrl: 'https://www.pansurg.org/remotegallery/holovideo1.mp4',
        renderItem: this._renderVideo.bind(this)
      }
      
  ]
    return (
     
        <ImageGallery style={{maxHeight:422}} items={images} thumbnailPosition='left' lazyload={true} showBullets='true' useBrowserFullscreen={false} showPlayButton={false}/>
    
    )
}
}

