import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import logo from '../images/logo_remote.svg'
import logo_microsoft from "../images/Logo_microsoft.svg"
import InterestForm from '../components/interestForm'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Gallery from '../components/remotegallery'

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "tower_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {

  return (
  <>
    <GatsbySeo title='PanSurg REMOTE'
          description='Mixed-reality healthcare powered by the Microsoft HoloLens™'/>
      <Hero title="PanSurg REMOTE"
            subtitle="Mixed-reality healthcare powered by the Microsoft HoloLens™"
            fluid={data.hero.childImageSharp.fluid}
            breadcrumbs
        secondcrumb = 'Research'
        secondlink = '/research'
        activecrumb = 'REMOTE'
        />

      <section className="section is-small" style={{marginTop:'2rem'}}>
        <div className="container">
          <div className="is-thin">

          <div className="has-text-centered">
            <img src={logo} width="400px" alt="REMOTE Logo" style={{marginBottom:'2rem'}}/>
          </div>

          <div className="content">
            <p>The COVID-19 pandemic presents unparalleled challenges for the delivery of safe and
            effective care at scale in the here and now across all health systems. Rapid innovation
            and the use of new and evolving technologies may present a range of flexible and adaptable
            solutions that can expand accessibility to care, reduce the risk of healthcare worker infection,
            alleviate physician burden, reduce protective equipment use, and support the delivery of
            education and training.</p>

            <p>PanSurg REMOTE is working with the HoloLens™ from Microsoft to deploy a mixed-reality
            headset capability to aid in the response to COVID-19 across the full spectrum of care
            delivery and medical training. The aims of the project are:</p>
            <ul>
              <li>to establish the safety, feasibility and efficacy of HoloLens™ in supporting
              the delivery of distributed clinical care during the COVID-19 pandemic</li>
              <li>to develop and evaluate the use of HoloLens™ in the delivery of real-time
              and on-demand healthcare education, training and supervision during the COVID-19 pandemic</li>
            </ul>

            <p>
              PanSurg REMOTE is leading the work with a number of NHS and Academic partners
              in the rapid delivery of this innovative technology.
            </p>
          </div>

          </div>
        </div>
      <br />
        <div className="container">
          <div className="is-thin">
          <p className="subtitle has-text-darkBlue">Microsoft HoloLens™ Collaboration Introductory Video</p>
          <p><div style={{position:'relative', width:"100%", height:350}}><div className="has-text-white" style={{position:'absolute', top:'0', left:'0', padding:'1%', backgroundColor:'rgba(0, 0, 0, .4)'}}>Video Credits: Kyle Lam</div><iframe src="https://drive.google.com/file/d/12jYX0dq-BU10fPRxV-lKGp7vDhd0_DkE/preview" style={{width: "100%", height: "100%"}}></iframe></div></p>
         <br />
          <p className="subtitle has-text-darkBlue">Gallery</p>
            <Gallery />
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="is-thin">
          <p className="subtitle has-text-darkBlue">Industry Collaborators</p>

          <div className="logos is-flex-tablet" style={{justifyContent:'space-around'}}>
            <a href="https://www.microsoft.com/en-gb"><img width="200px" src={logo_microsoft} alt="microsoft_logo"/></a>
          </div>

          </div>
        </div>
      </section>
  </>
  )
}
